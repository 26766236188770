import * as THREE from 'three/build/three.module';

export default class Background {
    constructor(container){
        if(!container) return;

        this.container = container;

        this.windowHalfX = window.innerWidth / 2;
        this.windowHalfY = window.innerHeight / 2;

        this.mouseX = 0;
        this.mouseY = 0;

        document.addEventListener('mousemove', e => { this.onDocumentMouseMove(e); }, false);
        
        this.camera = new THREE.PerspectiveCamera(95, window.innerWidth/window.innerHeight, 1, 20000);
        this.camera.position.z = 500;

        this.scene = new THREE.Scene();

        let geometry = new THREE.CylinderGeometry(0, 100, 100, 3);

        var materials = [
            new THREE.MeshPhongMaterial({
                specular: '#294257', // light
                color: '#294257', // intermediate
                emissive: '#294257', // dark
                shininess: 50 ,
                transparent: true,
                opacity: 0.9,
                overdraw: true
            }),
            new THREE.MeshPhongMaterial({
                specular: '#202b44', // light
                color: '#202b44', // intermediate
                emissive: '#202b44', // dark
                shininess: 50 ,
                transparent: true,
                opacity: 0.9,
                overdraw: true
            })
        ];

        this.group = new THREE.Object3D();

        for(var i=0;i<200;i++){
            var mesh = new THREE.Mesh(geometry, materials[Math.floor(Math.random() * materials.length)]);
            mesh.position.x = Math.random() * 2000 - 1000;
            mesh.position.y = Math.random() * 2000 - 1000;
            mesh.position.z = Math.random() * 2000 - 1000;
            mesh.rotation.x = Math.random() * 2 * Math.PI;
            mesh.rotation.y = Math.random() * 2 * Math.PI;
            mesh.opacity = 50;
            mesh.matrixAutoUpdate = false;
            mesh.updateMatrix();
            this.group.add(mesh);
        }

        this.scene.add(this.group);

        var directionalLight = new THREE.DirectionalLight(0xffffff);
        directionalLight.position.set(1, 1, 1).normalize();
        directionalLight.intensity = 0.2;
        this.scene.add(directionalLight);

        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setClearColor(0x202b44, 0);
        this.renderer.sortObjects = false;
        this.container.appendChild(this.renderer.domElement);

        window.addEventListener('resize', () => { this.onWindowResize(); }, false);
        
        this.animate();
    }

    animate(){
        requestAnimationFrame(() => { this.animate(); });
        this.render();
    }

    render(){
        this.camera.position.x += ( this.mouseX - this.camera.position.x ) * .0020;
        this.camera.position.y += ( - this.mouseY - this.camera.position.y ) * .0020;

        this.camera.lookAt(this.scene.position);

        var currentSeconds = Date.now();
        this.group.rotation.x = Math.sin( currentSeconds * 0.0007 ) * 0.05;
        this.group.rotation.y = Math.sin( currentSeconds * 0.0003 ) * 0.05;
        this.group.rotation.z = Math.sin( currentSeconds * 0.0002 ) * 0.05;

        this.renderer.render(this.scene, this.camera);
    }

    onWindowResize(){
        this.windowHalfX = window.innerWidth / 4;
        this.windowHalfY = window.innerHeight / 4;

        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(window.innerWidth, window.innerHeight);
    }

    onDocumentMouseMove(event){
        this.mouseX = ( event.clientX - this.windowHalfX ) * 2;
        this.mouseY = ( event.clientY - this.windowHalfY ) * 2;
    }
}