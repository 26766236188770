import $ from 'jquery';
import Background from './Background';
import WorksHover from './WorksHover';
import MainTemplate from '../templates/main.html';
import Scrollbar from 'smooth-scrollbar';

export default class App{
    constructor(container){
        this.$container = $(container);

        this.render();
    }

    render(){
        this.$container.html(MainTemplate);

        new Background($('#fixed-bg').get(0));

        new WorksHover(
            $('#works-hover-container').get(0),
            $('#works-list').get(0)
        );

        Scrollbar.init($('#main').get(0), {
            damping: 0.12
        }).addListener(listener => {
            this.hideNavbar(listener);
            this.blurBackgroundOnLast();
        });

        this.initCursor();

        this.initNavbarAnchor();

        $('body').addClass('app-initialized');
    }

    hideNavbar(listener){
        if(window.SCROLLBAR_SCROLLED_Y < listener.offset.y)
            $('#navbar').addClass('hide');
        else
            $('#navbar').removeClass('hide');

        window.SCROLLBAR_SCROLLED_Y = listener.offset.y;
    }

    blurBackgroundOnLast(){
        let scrollTop = Scrollbar.get($('#main').get(0)).scrollTop,
            target = $('#contact').position().top - 200;

        if(scrollTop >= target){
            let pixels = (scrollTop - target)/10;

            $('#fixed-bg').css('filter', `blur(${pixels}px)`);
            $('#fixed-bg').css('-webkit-filter', `blur(${pixels}px)`);
            $('#fixed-bg').css('-moz-filter', `blur(${pixels}px)`);
        }else{
            $('#fixed-bg').css('filter', 'blur(0px)');
            $('#fixed-bg').css('-webkit-filter', 'blur(0px)');
            $('#fixed-bg').css('-moz-filter', 'blur(0px)');
        }
    }

    initCursor(){
        let clientX, clientY;

        let isLink;

        $('body').on('mousemove', e => {
            clientX = e.clientX;
            clientY = e.clientY;

            let $target = $(e.target);

            if($target.is("a"))
                isLink = true;
            else
                isLink = false;
        });

        const render = () => {
            requestAnimationFrame(render);

            $('#cursor').css('left', `${clientX}px`);
            $('#cursor').css('top', `${clientY}px`);

            if(isLink){
                $('#cursor').addClass('hovered-anchor');
            }else{
                $('#cursor').removeClass('hovered-anchor');
            }
        };

        render();
    }

    initNavbarAnchor(){
        $('.navbar-nav').on('click', 'a', function(e){
            e.preventDefault();

            var hash = this.hash;

            if(!$(hash).length)
                return;

            const pixels = $(hash).position().top;

            Scrollbar.get($('#main').get(0)).scrollTo(0, pixels, 800);
        });
    }
}